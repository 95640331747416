import { Link } from 'react-router-dom';
import contactImg from '../Gallery/15.jpg';
import instagram from '../social/instagram.png';
import './Menu.css';

const Contact = () => {
  return (
    <div className='contact-wrapper'>
      <div
        className='contact'
        style={{ backgroundImage: `url(${contactImg})` }}></div>
      <h2>Contact Us</h2>
      <div className='details'>
        <Link to='https://www.instagram.com/jbcbraids/' target='_blank'>
          <img src={instagram} alt='instagram logo' />
          <h5>JBCBRAIDS</h5>
        </Link>
        <p>DM us on instagram for more enquiries</p>
      </div>
    </div>
  );
};

export default Contact;
