import React from "react";
import "./Footer.css";
import facebook from "../social/facebook.png";
import instagram from "../social/instagram.png";
import tik from "../social/tik.png";
import youtube from "../social/youtube.png";
import { Link } from "react-router-dom";
import ShareButtons from "../ShareButton";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
      <p className="text-center text-light mb-4">FOLLOW US</p>
      <div className="social">
        <Link
          to="https://web.facebook.com/jannybeautycare03/?_rdc=1&_rdr"
          target="_blank"
        >
          <img src={facebook} alt="" />
        </Link>

        <Link to="https://www.instagram.com/jbcbraids/" target="_blank">
          <img src={instagram} alt="" />
        </Link>

        <Link to="https://www.tiktok.com/@janny_jbc?lang=en" target="_blank">
          <img src={tik} alt="" />
        </Link>

        <Link to="https://shorturl.at/An2Pv" target="_blank">
          <img src={youtube} alt="" />
        </Link>
      </div>
      <hr className="hr-footer" />
      <p className="text-center text-light ">SHARE</p>
      <div className="sss">
        <ShareButtons />
      </div>
      <hr className="hr-footer" />

      <p className="text-center text-light copyright">
        &copy;{currentYear}
        <Link to="https://www.instagram.com/jbcbraids/" target="_blank">
          <strong> JBC</strong>
        </Link>
        <p> All rights reserved.</p>
      </p>
    </div>
  );
};

export default Footer;
