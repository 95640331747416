import Iframe from "./Iframe";
import "./Menu.css";

const Services = () => {
  return (
    <div className="service">
      <Iframe />
    </div>
  );
};

export default Services;
