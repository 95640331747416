import "./hero.css";
import InfiniteAnimation from "../infinite/InfiniteAnimation";
import Home from "../updates/Home/Home.jsx";
import PrimaryBtn from "../updates/buttons/PrimaryBtn.jsx";

const Hero = () => {
  return (
    <div className="heroBody text-center bg-pink-100 ">
      <Home />
      <InfiniteAnimation />
      <PrimaryBtn btnName={"Select Your Style"} link={"/services"} />
    </div>
  );
};

export default Hero;
